import React from "react"
import ReactDOM from "react-dom/client"
import { Settings } from "luxon"
import { setLocale } from "yup"

import "./tailwind.css"
import "./main.css"

import App from "./App"
import { defaultValidationMessages } from "./common/validationMessages"
import { initTracking } from "./tracking/tracking"

if (import.meta.env.MODE === "production") {
	initTracking()
}
Settings.defaultLocale = "en"
setLocale(defaultValidationMessages)

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
